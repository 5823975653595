<!-- <style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    /deep/ {
        .modal-body{
            overflow: visible !important;
        }
        #datetime-wrp {
            width: fit-content;
            .vdatetime {
                width: 16rem;
                display: inline-block;
            }
            .picker {
                display: inline-flex;
                height: 37px;
                background: $blue1;
                width: 29px;
                align-items: center;
                justify-content: center;
                margin: 0;
                cursor: pointer;
                i {
                    color:white;
                }
            }
            .control-label {
                display: block;
            }
        }
        .bootstrap-datetimepicker-widget {
            td {
                .active,
                a.btn {
                    color: #f39c12;
                }
            }

            .day.active {
                background-color: #f39c12;
            }
        }
        .vdatetime-input {
            font-family:"Source Sans Pro", sans-serif;
            font-size: 14px;
            width: 100%;
            height: 100% !important;
            border: none;
            padding: 0;
            margin: 0;
        }
        .control-label {
            padding-left: 0;
            font-size: 1rem;
            font-family: $font-default;
        }
        .vdatetime-popup__header {
            background-color: $primary-color !important;
        }
        .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
            background-color: $primary-color !important;
        }
        .vdatetime-popup__actions__button {
            color: $primary-color !important;
        }
        .vdatetime-time-picker__item--selected {
            color: $primary-color !important;
        }
    }
</style>
<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body">
            <cc-loader v-show="loading"/>
            <div class="row justify-content-center" v-show="!loading">
                <div class="col-sm-3">
                    <label for="inputCodigo" class="control-label">Embalagem</label>
                    <cc-select-v2 :tracker="'value'" :text="'name'" v-model="wrap" :options="wraps"></cc-select-v2>
                </div>
                <div class="col-sm-3">
                    <label for="inputCodigo" class="control-label">Quantidade</label>
                    <cc-input type="text" pattern="[0-9]" v-model="amount" :disabled="wrap.value == 'UN' || wrap.value == 'DZ'" />
                </div>
            </div>
        </div>
    </cc-modal>
</template>

<script>

import ProductService from "@/services/v2/products.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default {
    props: ["product"],
    data() {
        return {
            service: new ProductService(),
            loading: false,
            wrap: null,
            amount: null,
            wraps: [
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Duzia', value: 'DZ' }
            ],
            modal: {
                title: `#${this.product.descricaoProduto}`,
                permitted: true,
                submit : this.update_wrap,
                subtitle: "Altere aqui o tipo e quantidade da embalagem do produto",
                submit_text: 'Atualizar',
                style: {
                    width: "50%"
                }
            }
        };
    },
    watch: {
        'wrap.value' : function(new_val) {
            if(new_val == 'UN') {
                this.amount = "1"
            } else if(new_val == 'DZ') {
                this.amount = "12"
            }
        },
        'amount': function(new_val) {
            if(new_val.match(/[^0-9]/g)) {
                this.amount = this.amount.replace(/[^0-9]/g, '')
            }
        }
    },
    methods: {
        open_datepicker() {
            document.getElementsByClassName('vdatetime-input')[0].click()
        },
        close(reload = false) {
            this.$emit("close", reload);
            if(reload) {
                this.$emit("reload", false);
            }
        },
        update_wrap() {

            this.loading = true;

            var payload = { wrap: `${this.wrap.value} ${this.amount.replace(/[\.,]/g, '')}` }

            this.service.update_wrap(this.product.idProduto, payload)
            .then(response => response.data)
            .then(() => this.$set(this.product, 'embalagem', payload.wrap))
            .then(() => this.$set(this.product, 'qtd_embalagem', this.amount))
            .then(data => this.close(payload))
            .catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.amount = this.product.embalagem.split(" ")[1]
        this.wrap = { name: this.product.embalagem.split(" ")[0], value: this.product.embalagem.split(" ")[0]}
    }
};
</script> -->

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    /deep/ {
        .modal-body{
            overflow: visible !important;
        }
        #datetime-wrp {
            width: fit-content;
            .vdatetime {
                width: 16rem;
                display: inline-block;
            }
            .picker {
                display: inline-flex;
                height: 37px;
                background: $blue1;
                width: 29px;
                align-items: center;
                justify-content: center;
                margin: 0;
                cursor: pointer;
                i {
                    color:white;
                }
            }
            .control-label {
                display: block;
            }
        }
        .bootstrap-datetimepicker-widget {
            td {
                .active,
                a.btn {
                    color: #f39c12;
                }
            }

            .day.active {
                background-color: #f39c12;
            }
        }
        .vdatetime-input {
            font-family:"Source Sans Pro", sans-serif;
            font-size: 14px;
            width: 100%;
            height: 100% !important;
            border: none;
            padding: 0;
            margin: 0;
        }
        .control-label {
            padding-left: 0;
            font-size: 1rem;
            font-family: $font-default;
        }
        .vdatetime-popup__header {
            background-color: $primary-color !important;
        }
        .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
            background-color: $primary-color !important;
        }
        .vdatetime-popup__actions__button {
            color: $primary-color !important;
        }
        .vdatetime-time-picker__item--selected {
            color: $primary-color !important;
        }
    }
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container {
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.product-modal-title{
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    font-size: 2.25em;
    border-radius: 10px 10px 0 0;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding: 1vh 1vw;
    align-items: center;
}
.modal-close-icon{
    font-size: 1.5em;
    cursor: pointer;
}
.product-modal-body{ padding: 2vh 2vw; }
.product-modal-body-inputs{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 5vh;
}
.product-modal-input-title{
    font-weight: 400;
    font-size: 2em;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}
.product-modal-input{
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 0.5vw;
    margin-top: 5px;
    width: 15vw;
    font-size: 16px;
}
.product-modal-input:focus{outline: none;}
.product-modal-quantity{ margin-left: 3vw; }
.product-modal-body-buttons{
    display: flex;
    justify-content: flex-end;
    margin-top: 4vh;
}
.second-button{
    margin-left: 1.5vw;
}
.product-modal-loader{
    margin-top: 5vh;
    margin-bottom: 5vh;
}
</style>
<template>
    <!-- <cc-modal :modal="modal" @close="close">
        <div slot="body">
            <cc-loader v-show="loading"/>
            <div class="row justify-content-center" v-show="!loading">
                <div class="col-sm-3">
                    <label for="inputCodigo" class="control-label">Embalagem</label>
                    <cc-select-v2 :tracker="'value'" :text="'name'" v-model="wrap" :options="wraps"></cc-select-v2>
                </div>
                <div class="col-sm-3">
                    <label for="inputCodigo" class="control-label">Quantidade</label>
                    <cc-input type="text" pattern="[0-9]" v-model="amount" :disabled="wrap.value == 'UN' || wrap.value == 'DZ'" />
                </div>
            </div>
        </div>
    </cc-modal> -->
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="product-modal-title">
                {{product.descricaoProduto}}
                <span v-on:click="close" class="material-icons modal-close-icon">cancel</span>
            </div>
            <cc-loader class="product-modal-loader" v-show="loading"/>
            <div v-show="!loading" class="product-modal-body">
                <div class="product-modal-body-inputs">
                    <StandardInputAuto :action="value => wrap= value" :title="'Embalagem'" :width="'18vw'" :selectWidth="'14.6vw'" :placeholder="wrap.value" :list="formatList(wraps)" :type="'select'"/>
                    <div class="product-modal-quantity">
                        <div class="product-modal-input-title">Quantidade</div>
                        <input v-model="amount" :disabled="wrap.value == 'UN' || wrap.value == 'DZ'" class="product-modal-input" type="number">
                    </div>
                </div>
                <div class="product-modal-body-buttons">
                    <Button :text="'Cancelar'" :action="close"/>
                    <Button class="second-button" :text="'Atualizar'" :action="update_wrap"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ProductService from "@/services/v2/products.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Button from "@/components/ui/buttons/StandardButton.vue"

export default {
    props: ["product"],
    mixins: [ loaderMixin ],
    components:{
        StandardInputAuto,
        Button
    },
    data() {
        return {
            service: new ProductService(),
            loading: false,
            wrap: null,
            amount: null,
            wraps: [
                { name: 'Atado', value: 'AT' },
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Quilo', value: 'KG' },
                { name: 'Display', value: 'DP' },
                { name: 'Duzia', value: 'DZ' }
            ],
            modal: {
                title: `#${this.product.descricaoProduto}`,
                submit : this.update_wrap,
                subtitle: "Altere aqui o tipo e quantidade da embalagem do produto",
                submit_text: 'Atualizar',
                style: {
                    width: "50%"
                }
            }
        };
    },
    watch: {
        'wrap.value' : function(new_val) {
            if(new_val == 'UN') {
                this.amount = "1"
            } else if(new_val == 'DZ') {
                this.amount = "12"
            }
        },
        'amount': function(new_val) {
            if(new_val.match(/[^0-9]/g)) {
                this.amount = this.amount.replace(/[^0-9]/g, '')
            }
            this.modal = { ...this.modal, permitted: parseInt(this.amount) > 0 }
        }
    },
    methods: {
        formatList (wraps){
            const resp = []
            wraps.forEach(element => {
                resp.push({
                    value: element,
                    text: element.name
                })
            })
            return resp
        },
        open_datepicker() {
            document.getElementsByClassName('vdatetime-input')[0].click()
        },
        close() {
            this.$emit("close", { wrap: `${this.wrap.value} ${this.amount.replace(/[\.,]/g, '')}` });
        },
        update_wrap() {
            if (!this.amount) {
                this.snack('Por favor, insira um valor para o campo "quantidade".', 'error')
                return
            }
            this.loading = true;
            var payload = { wrap: `${this.wrap.value} ${this.amount.replace(/[\.,]/g, '')}` }
            this.service.update_wrap(this.product.idProduto, payload)
            .then(response => response.data)
            .then(() => this.$set(this.product, 'embalagem', payload.wrap))
            .then(() => this.$set(this.product, 'qtd_embalagem', this.amount))
            .then(() => this.close(payload))
            .catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
        this.amount = this.product.embalagem.split(" ")[1]
        this.wrap = { name: this.product.embalagem.split(" ")[0], value: this.product.embalagem.split(" ")[0]}
    }
};
</script>

